<template>
  <div class="root_container" :style="styleObject">
    <div style="padding-left: 10px;padding-right: 10px;">
      <div @click="gotoCheckout()" class="root_order_total" v-if="currentRouteName && ['Order', 'Category'].includes(currentRouteName)">
        <div style="width: 25%;" v-if="isLogined && rewardSelect && rewardSelect.length > 0">
          <div
            class="text_reward_title"
          >
            {{
              rewardSelect[0].reward_type == 1
                ? "-" + voucher_deducted_format
                : "-" + voucher_deducted_format
            }}
          </div>
        </div>
        <div style="width: 40%;margin-bottom: 10px;" v-if="isLogined && rewardSelect && rewardSelect.length > 0">
          <span>Cart {{ itemQuantity }} items</span>
        </div>
        <div style="width: 65%;" v-else>
          <span>Cart {{ itemQuantity }} items</span>
        </div>
        <div style="width: 35%;">
          <div
            style="display: inline-flex;width:100%;"
          >
            <div
              class="text_total_order"
              style="color: black;text-align:right;width: 100%;"
              v-if="
                checkEnableVoucher() && this.rewardSelect && this.rewardSelect[0]
              "
            >
              S$ {{ calculateTotal() }}
            </div>
            <div class="text_total_order" style="color: black;text-align:right;width: 100%;" v-else>S$ {{ calculateTotal() }}</div>
          </div>
        </div>
        <!-- <div style="display: inline-flex; height: 70%">
          <div
            v-if="rewardSelect && rewardSelect.length > 0"
            class="text_reward_title"
          >
            {{
              rewardSelect[0].reward_type == 1
                ? "-" + voucher_deducted_format
                : "-" + voucher_deducted_format
            }}
          </div>

          <div class="div_quantity" style="width: 15%">
            <div>
              <img
                :style="
                  voucher_deducted_format != '' ? 'width: 100%;' : 'width: 80%;'
                "
                src="../../assets/img/my-cart/cart.png"
              />
            </div>
            <div
              class="text_quantity"
              :style="
                voucher_deducted_format != '' ? 'right: -5px;' : 'right: 5px;'
              "
            >
              {{ itemQuantity }}
            </div>
          </div>
          <div
            style="display: inline-flex; padding-left: 10px; gap: 5px"
            :style="{
              width: rewardSelect && rewardSelect.length > 0 ? '50%' : '60%',
            }"
          >
            <div
              class="text_total_order"
              style="color: black"
              v-if="
                checkEnableVoucher() && this.rewardSelect && this.rewardSelect[0]
              "
            >
              S$ {{ calculateTotal() }}
            </div>
            <div class="text_total_order" v-else>S$ {{ calculateTotal() }}</div>
            <div
              class="text_total_voucher_discount"
              v-if="
                checkEnableVoucher() && this.rewardSelect && this.rewardSelect[0]
              "
            ></div>
            <div v-else class="text_total_voucher_discount">
            </div>
          </div>
          <div @click="gotoCheckout()" class="btn_proceed" style="width: 25%">
            Proceed
          </div>
        </div> -->
        <div v-if="isLogined && this.rewardSelect && this.rewardSelect[0]" style="position: absolute;bottom: 4px;left:27.5%;">
          <div
            v-if="checkEnableVoucher()"
            style="width: 100%; color: rgb(240, 75, 94); font-size: 9.5px"
          >
            Order S${{ Number(this.rewardSelect[0].order_total - calculateTotal()).toFixed(2) }} more
            to get
            {{
              rewardSelect[0].reward_type == 1
                ?  Number(rewardSelect[0].reward_unit).toFixed(2) + "% Off"
                : "S$" +  Number(rewardSelect[0].reward_unit).toFixed(2) + " Off"
            }}
          </div>
          <div
            v-else
            style="width: 100%; color: rgb(240, 75, 94); font-size: 11px"
          >
            <div style="color: green">Voucher Applied</div>
          </div>
        </div>
      </div>
    </div>
    <div class="root_my_reward">
      <div style="width: 25%;height: 100%;" :class="{ 'active': currentRouteName && currentRouteName == 'Order'}">
        <div style="cursor: pointer; width: 100%;height: 50%" @click="gotoHome()">
          <i
            class="material-icons-round opacity-10 cursor-pointer text-center active icon_footer"
            >home</i
          >
        </div>
        <div style="font-size: 14px;cursor: pointer; width: 100%;height: 50%;text-align: center;">Home</div>
      </div>
      <div style="width: 25%;height: 100%;" :class="{ 'active': currentRouteName && currentRouteName == 'MyReward'}">
        <div style="cursor: pointer; width: 100%;height: 50%" @click="gotoMyReward()">
          <i
            class="material-icons-round opacity-10 cursor-pointer text-center active icon_footer"
            >discount</i
          >
        </div>
        <div style="font-size: 14px;cursor: pointer; width: 100%;height: 50%;text-align: center;">Rewards</div>
      </div>
      <div style="width: 25%;height: 100%;" :class="{ 'active': currentRouteName && currentRouteName == 'OrderHistory'}">
        <div style="cursor: pointer; width: 100%;height: 50%" @click="gotoOrderHistory()">
          <i
            class="material-icons-round opacity-10 cursor-pointer text-center active icon_footer"
            >format_list_bulleted</i
          >
        </div>
        <div style="font-size: 14px;cursor: pointer; width: 100%;height: 50%;text-align: center;">Orders</div>
      </div>
      <div style="width: 25%;height: 100%;" :class="{ 'active': currentRouteName && currentRouteName == 'Profile'}">
        <div style="cursor: pointer; width: 100%;height: 50%" @click="gotoProfile()">
          <i
            class="material-icons-round opacity-10 cursor-pointer text-center active icon_footer"
            >person</i
          >
        </div>
        <div style="font-size: 14px;cursor: pointer; width: 100%;height: 50%;text-align: center;">Me</div>
      </div>
    </div>
  </div>
</template>

<script>
import PromocodeService from "../../services/promocode.service";
import EventBus from "vue3-eventbus";
import { useRoute } from 'vue-router';
import { localStorageName } from '@/config/utils';

export default {
  name: "OrderFooter",
  components: {},
  setup() {
    const route = useRoute();
    const currentRouteName = route.name;

    return {
      currentRouteName
    };
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      pathType: "",
      tableCode: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      itemQuantity: 0,
      rewardSelect: JSON.parse(localStorage.getItem(localStorageName('SELECTED_REWARD'), [])),
      itemSelect: [],
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
      voucher_deducted_format: "",
      voucher_deducted: "",
      isLogined: false,
      isYourFriendLogined: false,
      cartName: 'ORDER_INFO',
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch('cartName');
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;
    this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), {}));

    if (this.tableInfo) {
      this.isYourFriendLogined = this.tableInfo.is_member_order ?? false;
    }

    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
    this.itemSelect = orderInfo;

    orderInfo.forEach((item) => {
      this.itemQuantity += item.quantity;
    });

    if(this.memberInfo || this.isYourFriendLogined) {
      this.isLogined = true;
    }

    this.calculateVoucherPrice();
  },
  mounted() {
    EventBus.on("AddToCartSuccess", async () => {
      setTimeout(() => {
        this.itemQuantity = 0;
        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        this.itemSelect = orderInfo;
        orderInfo.forEach((item) => {
          this.itemQuantity += item.quantity;
        });
        this.calculateVoucherPrice();
      }, 300);
    });
    EventBus.on("ChangeItemQuantitySuccess", async () => {
      setTimeout(() => {
        this.itemQuantity = 0;
        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        this.itemSelect = orderInfo;
        orderInfo.forEach((item) => {
          this.itemQuantity += item.quantity;
        });
        this.calculateVoucherPrice();
      }, 300);
    });
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    gotoCheckout() {
      if (this.itemSelect && this.itemSelect.length) {
        this.gotoPath("MyCart");
      }
    },

    gotoAddress() {
      if (this.branch.destination_link) {
        window.open(this.branch.destination_link, "_blank");
      }
    },

    calculateTotal() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        return this.itemSelect
          .reduce((total, product) => {

            // if (this.isLogined && product.member_price_no_modifier_option > 0) {
            //   // product.member_price = product.member_price;
            // } else {
            //   product.member_price = product.price_default;
            // }

            if(this.isLogined) {
              total += product.member_price ? (product.member_price * product.quantity) : 0
            } else {
              total += product.price_default ? (product.price_default * product.quantity) : 0
            }

            // if (this.isLogined && product.modifier_group && product.modifier_group.length > 0 && product.member_price > 0) {
            //   total += product.member_price * product.quantity;
            // } else if(this.isLogined && product.modifier_group && product.modifier_group.length == 0 && product.member_price_no_modifier_option > 0) {
            //   total += product.member_price_no_modifier_option * product.quantity;
            // } else if(!this.isLogined && product.modifier_group && product.modifier_group.length > 0 && product.price_default > 0) {
            //   total += product.price_default * product.quantity;
            // } else if(!this.isLogined && product.modifier_group && product.modifier_group.length == 0 && product.price_default_no_modifier_option > 0) {
            //   total += product.price_default_no_modifier_option * product.quantity;
            // } else {
            //   total += 0;
            // }

            // product.modifier_group.forEach((group) => {
            //     group.items.forEach((item) => {
            //         total += item.price * item.quantity;
            //     });
            // });

            return total;
          }, 0)
          .toFixed(2);
      } else {
        return "0.00";
      }
    },

    checkEnableVoucher() {
      if (this.rewardSelect && this.rewardSelect.length > 0) {
        if (this.rewardSelect[0].order_minimum == 2) {
          if (this.calculateTotal() < this.rewardSelect[0].order_total) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return false;
    },

    calculateVoucherPrice() {
      if (
        this.memberInfo &&
        this.rewardSelect &&
        this.rewardSelect.length > 0
      ) {
        let dataForm = {
          branch_code: this.branchCode,
          memberId: this.memberInfo.id,
          voucherCode: this.rewardSelect[0].code,
          totalOrder: this.calculateTotal(),
        };

        PromocodeService.calculateVoucherPrice(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucher_deducted_format =
                response.data.data.voucher_deducted_format;
              this.voucher_deducted = response.data.data.voucher_deducted;
            } else {
              // this.snackbar = "danger";
              // this.message = response.data.result.message;
              // this.setTimeOutNotification();
            }
            // this.hideLoader();
          },
          (error) => {
            console.log(error);
            // this.snackbar = "danger";
            // this.message = error;
            // this.hideLoader();
          }
        );
      }
    },

    gotoProfile() {
      this.gotoPath("Profile");
    },

    gotoHome() {
      this.gotoPath("Order");
    },

    gotoOrderHistory() {
      this.gotoPath("OrderHistory");
    },

    gotoMyReward() {
      this.gotoPath("MyReward");
    },

    gotoPath(name) {
      this.$router.push({ name: name, params: {
        merchant_code: this.merchantCode,
        type: this.pathType,
        branch_code: this.branchCode,
        table_code: this.tableCode
      } });
    },
  },
};
</script>

<style scoped>
.root_order_total {
  background: var(--color);
  gap: 5px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 40%;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
  cursor: pointer;
}
.text_reward_title {
  text-align: center;
  border: 1px solid orangered;
  border-radius: 5px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
}
.div_quantity {
  padding: 5px;
  position: relative;
  width: 15%;
}
.text_quantity {
  position: absolute;
  top: 0px;
  width: 20px;
  height: 20px;
  background: orangered;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 10px;
}
.text_total_order {
  font-size: 20px;
  font-weight: 800;
}
.text_total_voucher_discount {
  /* width: 30%; */
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  align-self: center;
  color: black;
  font-size: 12px;
  font-weight: 600;
  text-decoration: line-through;
}
.btn_proceed {
  background-color: orangered;
  border-radius: 5px;
  width: 25%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  align-self: center;
  color: white;
  font-weight: bold;
  height: 55px;
}
.root_my_reward {
  display: flex;
  width: 100%;
  background: white;
  /* padding-left: 24px; */
  /* padding-right: 24px; */
  height: 100%;
  justify-content: space-between;
  /* height: 50%; */
  height: 52px;
}
.root_my_reward > div {
  padding-top: 5px;
  border-top: 0.5px solid lightgrey;
}
.root_my_reward > div.active {
  color: var(--color) !important;
  border-color: var(--color);
}

.root_my_reward > div.active .icon_footer {
  color: var(--color) !important;
}
header {
  background: var(--color);
  /* position: fixed; */
  /* top:0; */
  /* left:0; */
  z-index: 99999;
  width: 100%;
  height: 100%;
}

header > div {
  padding: 15px 24px 10px 24px;
}

header .material-icons-round {
  font-size: 32px;
}

header .outlet-name {
  font-size: 16px;
  font-weight: bold;
}

header .outlet-address {
  font-size: 11px;
  margin-top: -3px;
  cursor: pointer;
}

header .cart-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

header .cart-btn .quantity {
  position: absolute;
  top: -12px;
  right: -12px;
  padding: 2px 7px;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 13px;
  color: var(--color);
  font-weight: 500;
}

@keyframes itemQuantityFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

header .cart-btn .quantity > div {
  animation: 0.2s ease-out 0s 1 itemQuantityFromTop;
}

header .cart-btn .material-icons-round {
  font-size: 20px;
  color: var(--color);
}
.icon_footer {
  color: gray;
  width: 100%;
}
</style>
