<template>
  <div class="page-header align-items-start" :style="styleObject" id="page-success">
    <span class="mask bg-white"></span>

    <div class="container" style="padding-left: 0px; padding-right: 0px">
      <div class="row">
        <div class="notifications">
          <material-alert
            v-if="error"
            color="danger"
            icon="ni ni-like-2 ni-lg"
            dismissible
          >
            {{ error }}
          </material-alert>
        </div>
        <div class="col-12">
          <div class="card-body" style="background: var(--color); padding: 1px 5px">
            <div class="flex-container">
              <a @click="goBack" class="back-button">
                <span
                  class="material-icons md-30"
                  style="line-height: inherit; color: black; margin-top: -2px"
                  >close</span
                >
              </a>
              <div class="cart-title">Status</div>
            </div>
          </div>
          <div class="card-body mt-3">
            <div class="mb-1">
              {{ MERCHANT_BRAND_NAME ? MERCHANT_BRAND_NAME + " - " : "\xa0" }}
              {{ branch.branch_name }}
              {{ branch.branch_code }}
            </div>
            <div class="mb-1" style="display: flex;width: 100%;justify-content: space-between;">
              <div v-if="orderDetail && tableCode">Table: {{ tableCode }} - {{ orderDetail.table_no }}</div>
              <div>ID: {{ payment_order_id }}</div>
            </div>
            <div v-if="orderDetail && orderDetail.is_pos_offline == 1 && orderDetail.status != 1" style="color: red;">Payment is unfinished. Please contact cashier for help.</div>
            <div v-if="isRequestTimeOut || (orderDetail && orderDetail.is_expired == 1 && orderDetail.status != 1)" style="color: red;">Request timeout. Payment is unfinished. Please contact cashier for help.</div>
            <div v-if="orderDetail && orderDetail.wallet_not_enough == 1 && orderDetail.status != 1">
              
                <div style="width: 100%" class="text-center">
                  <div style="color: red;textAlign: left;">E-Wallet not enough.</div>
                  <button
                    @click="handleRetryWalletNotEnough()"
                    class="btn bg-green text-center mb-0 mt-3"
                    type="button"
                    style="
                      color: var(--color) !important;
                      background: transparent !important;
                      border-radius: 10px !important;
                      border: 1px solid var(--color) !important;
                    "
                  >
                    Retry
                  </button>
                </div>
              
            </div>
            <div
              class="d-flex align-items-center"
              v-if="branch && branch.branch_address"
            >
              <span
                class="material-icons color-green md-30"
                style="font-size: 15px; margin-right: 5px; width: 5%"
                >near_me</span
              >
              <div
                class="branch-info"
                style="
                  font-size: 12px;
                  line-height: normal;
                  display: flex;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                "
              >
                {{ branch.branch_address }} <span class="material-icons" style="width: 15px; color: var(--color);font-size: 13.5px;">error_outline_rounded</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="branch && branch.branch_address"
            >
              <span
                class="material-icons color-green md-30"
                style="font-size: 16px; margin-right: 5px"
                >alarm</span
              >
              <div class="branch-info" style="font-size: 12px">
                Current waiting time: {{ branch.waiting_time_text }}
              </div>
            </div>
            <div class="mt-3" style="display: inline-flex; align-items: center" v-if="orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail.is_pushed_to_megapos_database_real != 5 && !orderDetail.action_required">
              <span
                v-if="status > 0"
                class="material-icons me-3 color-green md-30"
                style="font-size: 40px"
                >check_circle</span
              >
              <!-- <img
                src="@/assets/img/tick.gif"
                class="avatar-md border-radius-lg"
                alt="logo"
                height="35"
              /> -->
              <div class="ms-2" v-if="status > 0">
                <div style="font-size: 18px;">
                  Thank you {{ memberName }}
                </div>
                <div style="font-size: 18px;" v-if="orderDetail">
                  {{ !orderDetail.is_pushed_to_megapos_database ? 'Confirming your order, please wait' : 'You order has been placed.' }}
                </div>
              </div>
              <div v-else style="display: flex;width: 100%;align-items: center;">
                <div style="font-size: 18px; display: -webkit-inline-box;" v-if="orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail.is_pushed_to_megapos_database_real != 5">
                  {{ !orderDetail.is_pushed_to_megapos_database ? 'Confirming your order, please wait' : 'You order has been placed.' }}
                  <div v-if="orderDetail && !orderDetail.is_pushed_to_megapos_database" class="loading-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div>
                <!-- <span
                  v-if="orderDetail"
                  class="material-icons md-30"
                  style="line-height: inherit; color: black;width: 10%;"
                  >card_giftcard_ccon</span
                > -->
              </div>
            </div>
            <!-- <div style="align-items: center;display: flex;justify-content: center;" v-if="orderDetail && !orderDetail.is_pushed_to_megapos_database">
              <img
                src="@/assets/img/loading-confirming-order.gif"
                class="avatar-md border-radius-lg"
                alt="logo"
                height="200"
              />
            </div> -->
            <div style="align-items: center;display: flex;justify-content: center;" v-if="orderDetail && orderDetail.is_pushed_to_megapos_database && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5 && !orderDetail.action_required">
              <span
                class="material-icons md-30"
                style="color: #2db550;font-size: 120px;"
                >check_circle_outline</span
              >
            </div>
            <div
              class="text-center d-flex align-items-center justify-content-center"
              v-if="status && status > 0 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5 && !orderDetail.action_required"
            >
              Order #{{ merchantCode }}
              -
              <span
                v-if="orderDetail"
                style="
                  padding-left: 5px;
                  font-size: 24px;
                  color: #2db550;
                "
                >{{ orderDetail.order_number }}</span
              >
            </div>
          </div>
          <div class="card-body pt-0">
            <div
              style="font-size: 18px;color: #8e272c;margin-bottom: 20px;"
              v-if="orderDetail && orderDetail.is_pushed_to_megapos_database_real == 2"
            >
              TABLE IS LOCKED BY CASHIER, PLEASE CONTACT STAFF FOR HELP IF
              THIS STATUS PERSIST.
            </div>
            <div style="border: 1px solid #ececec; padding: 10px" v-if="orderDetail && !orderDetail.action_required">
              <div style="font-size: 18px">Order Updates</div>
              <div
                v-if="orderDetail"
                style="
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  margin-top: 24px;
                "
              >
                <div
                  style="
                    width: 80%;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    gap: 15px;
                  "
                >
                  <div style="position: relative">
                    <div v-if="status == 0 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail.is_pushed_to_megapos_database_real != 5" style="width: 22px; height: 20px">
                      <span class="material-icons color-green" style=""
                        >check_circle</span
                      >
                    </div>
                    <div
                      v-else
                      style="
                        width: 10px;
                        height: 10px;
                        background: gray;
                        border-radius: 50%;
                      "
                    ></div>
                    <div v-if="tableCode == ''" :class="{ 'status-active': status == 0 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Confirming</div>
                    <div v-else :class="{ 'status-active': status == 0 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail.is_pushed_to_megapos_database_real != 5 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Confirming</div>
                  </div>
                  <div
                    style="border-bottom: 1px solid gray; width: 100px"
                  ></div>
                  <div style="position: relative">
                    <div v-if="status == 1" style="width: 22px; height: 20px">
                      <span class="material-icons color-green" style=""
                        >check_circle</span
                      >
                    </div>
                    <div v-else>
                      <div v-if="orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail.is_pushed_to_megapos_database_real != 5" style="
                        width: 10px;
                        height: 10px;
                        background: gray;
                        border-radius: 50%;
                      "></div>
                      <div v-else style="
                       width: 10px;
                        height: 10px;
                        background: red;
                        border-radius: 50%;
                      "><div class="circle-x"></div></div>
                    </div>
                    <div v-if="tableCode == ''" :class="{ 'status-active': status == 1 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Preparing</div>
                    <div v-else :class="{ 'status-active': status == 1 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Preparing</div>
                  </div>
                  <div
                    style="border-bottom: 1px solid gray; width: 100px"
                  ></div>
                  <div style="position: relative">
                    <div v-if="status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5" style="width: 22px; height: 20px">
                      <span class="material-icons color-green" style=""
                        >check_circle</span
                      >
                    </div>
                    <div
                      v-else
                      style="
                        width: 10px;
                        height: 10px;
                        background: gray;
                        border-radius: 50%;
                      "
                    ></div>
                    <div v-if="tableCode == ''" :class="{ 'status-active': status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Ready</div>
                    <div v-else :class="{ 'status-active': status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5 }" style="position: absolute; width: 80px; text-align: center;font-size: 14px; left: calc(50% - 40px); margin-top: 10px">Closed</div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <div style="font-size: 12px; color: gray;" v-if="orderDetail && !isOrderPayLater">
                  Paid $ {{ (parseFloat(orderDetail.payment_amount) + parseFloat(orderDetail.amount_direct_deduct)).toFixed(2) }} 
                  <span v-if="orderDetail.status != 0">
                    by <span class="font-weight-bold">{{ orderDetail.topup_direct_deduct == 0 ? 'creditcard.' : 'eWallet.' }}</span>
                  </span>
                </div>
                <div class="flex-container" style="font-size: 12px; color: gray" v-if="isLogined && orderDetail.payment_amount_child">
                  <div>Cashback Earned</div>
                  <div class="text-container">{{ '[+' + formatPrice(orderDetail.payment_amount_child.payment_amount) + ']' }}</div>
                </div>
                <div
                  class="d-flex align-items-center"
                  style="font-size: 12px; color: gray"
                >
                  Last updated: {{ latestUpdateTime }}
                  <span
                    class="material-icons ms-1"
                    style="font-size: 16px; color: #2db550"
                    >replay</span
                  >
                </div>
              </div>
              <div v-if="message_error_from_megapos">
                <div
                  class="d-flex align-items-center"
                  style="font-size: 12px; color: red"
                >
                  Error: {{ message_error_from_megapos }}
                </div>
              </div>
              <div v-if="((message_error_from_megapos && orderDetail && !orderDetail.is_pushed_to_megapos_database) || orderDetail && orderDetail.is_expired == 1)" class="card-body mt-3 pb-0">
                <div style="display: inline-flex; align-items: center; width: 100%">
                  <div style="width: 100%" class="text-center">
                    <button
                      @click="handleRetry()"
                      class="btn bg-green text-center mb-0"
                      type="button"
                      style="
                        color: var(--color) !important;
                        background: transparent !important;
                        border-radius: 10px !important;
                        border: 1px solid var(--color) !important;
                      "
                    >
                      Retry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0" v-if="orderDetail && (!orderDetail.action_required || orderDetail.pos_order_status == 'OPEN')">
            <div style="width: 100%;justify-content: space-between;padding-bottom: 10px;">
              <div style="width: 100%;height: auto;">
                <div class="div-order-items" v-for="(item, index) in itemSelect" :key="index" style="display: flex;align-items: center;">
                    <div style="width: 100%;display: flex;align-items: center;">
                        <div style="width: 10%;height: 40px;">
                            <div style="text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                {{ item.quantity }}
                            </div>
                        </div>
                        <div style="width: 3%"></div>
                        <div style="width: 87%;display: flex;">
                            <div style="width: 20%;align-items: center;display: flex;">
                                <img :src="item.image" style="width: 50px;height: 50px;"/>
                            </div>
                            <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                <div class="div-items" style="position: relative;">
                                    <div class="item-name" style="width: 70%;line-height: normal;">
                                        <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                        <div v-if="item.bundle_item.length">
                                          <div
                                            v-for="(bundleItem, bundleIndex) in item.bundle_item"
                                            :key="bundleIndex"
                                          >
                                            <div style="display:table;width:100%;" class="pad-left-right" :style="{ marginTop: bundleIndex == 0 ? '10px' : '0px' }">
                                              <div style="width:20px;display:table-cell;vertical-align:top; padding:4px;font-size: 12px;padding-left: 0px;">
                                                <div style="display: flex;display: flex;justify-content: end;">
                                                  <div><span style="font-size: 12px;">{{ bundleItem.quantity }}</span></div>
                                                  <div>&nbsp;x&nbsp;</div>
                                                </div>
                                              </div>
                                              <div style="display:table-cell;width:auto;">
                                                <span style="font-size: 12px;">{{ bundleItem.itemName }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                            <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                    <div class="item-name" style="width:100%">
                                                        <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                        <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                        <div style="display: grid;">
                                            <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, item.is_member ? item.is_member : (orderDetail && orderDetail.member_id > 0 ? false : true)) }}</span>
                                            {{ getMainItemPriceAndModifiersPrice(item, item.is_member ? item.is_member : (orderDetail && orderDetail.member_id > 0 ? false : true)) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div style="width: 85%;display: flex;">
                    <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in item.OrderItems.slice(0, 2)" :key="index">
                        <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                        <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                    </div>
                </div>
                <div v-if="item.OrderItems && item.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                    <div style="margin-top: -50px;margin-left: 20px;">+{{ item.OrderItems.length - 2 }}</div>
                </div> -->
            </div>
            </div>
            <!-- <div class="mb-1">
              {{ MERCHANT_BRAND_NAME ? MERCHANT_BRAND_NAME + " - " : "\xa0" }}
              {{ branch.branch_name }}
              {{ branch.branch_code }}
            </div>
            <div class="mb-1" style="display: flex;width: 100%;justify-content: space-between;">
              <div v-if="orderDetail && tableCode">Table: {{ tableCode }} - {{ orderDetail.table_no }}</div>
              <div>ID: {{ payment_order_id }}</div>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="branch && branch.branch_address"
            >
              <span
                class="material-icons color-green md-30"
                style="font-size: 15px; margin-right: 5px; width: 5%"
                >near_me</span
              >
              <div
                class="branch-info"
                style="
                  font-size: 12px;
                  line-height: normal;
                  display: flex;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                "
              >
                {{ branch.branch_address }} <span class="material-icons" style="width: 15px; color: var(--color);font-size: 13.5px;">error_outline_rounded</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="branch && branch.branch_address"
            >
              <span
                class="material-icons color-green md-30"
                style="font-size: 16px; margin-right: 5px"
                >alarm</span
              >
              <div class="branch-info" style="font-size: 12px">
                Current waiting time: {{ branch.waiting_time_text }}
              </div>
            </div> -->
          </div>
          <div class="card-body pt-0" v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5">
            <div style="font-size: 25px;font-weight: bold;text-align: center;">{{orderDetail.brand_name}}</div>
            <div v-if="orderDetail.physical_branch_address && orderDetail.physical_branch_address != ''" style="text-align: center;">{{orderDetail.physical_branch_address}}</div>
            <!-- <div style="text-align: center;">Address Line 2</div> -->
            <!-- <div style="text-align: center;">Tel: 6123 4567</div> -->
            <div style="text-align: center;" v-if="orderDetail.gst_amount > 0">GST No. {{ orderDetail.gst }}</div>
          </div>
          <div class="pt-0" style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid lightgray;margin-left: 1rem;margin-right: 1rem;" v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5">
              <div style="color: black;">Order <span style="color: gray;">#{{ merchantCode + '-' + orderDetail.order_number}}</span></div>
              <div style="color: gray;font-size: 14px;">{{ dateFormat(orderDetail.created_at) }}</div>
          </div>
          <div class="card-body pt-0" v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5">
            <div style="width: 100%;justify-content: space-between;padding-bottom: 10px;">
              <div style="width: 100%;height: auto;">
                <div class="div-order-items" v-for="(item, index) in itemSelect" :key="index" style="display: flex;align-items: center;">
                    <div style="width: 100%;display: flex;align-items: center;">
                        <!--<div style="width: 10%;height: 40px;">
                            <div style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                {{ item.quantity }}
                            </div>
                        </div>
                        <div style="width: 3%"></div>-->
                        <div style="width: 100%;display: flex;">
                            <!--<div style="width: 20%;align-items: center;display: flex;">
                                <img :src="item.image" style="width: 50px;height: 50px;"/>
                            </div>-->
                            <div style="width: 100%;align-items: center;justify-content: center;display: flex;">
                                <div class="div-items" style="position: relative;">
                                    <div class="item-name" style="width: 70%;line-height: normal;">
                                        <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                        <div v-if="item.bundle_item.length">
                                          <div
                                            v-for="(bundleItem, bundleIndex) in item.bundle_item"
                                            :key="bundleIndex"
                                          >
                                            <div style="display:table;width:100%;" class="pad-left-right" :style="{ marginTop: bundleIndex == 0 ? '10px' : '0px' }">
                                              <div style="width:20px;display:table-cell;vertical-align:top; padding:4px;font-size: 12px;padding-left: 0px;">
                                                <div style="display: flex;display: flex;justify-content: end;">
                                                  <div><span style="font-size: 12px;">{{ bundleItem.quantity }}</span></div>
                                                  <div>&nbsp;x&nbsp;</div>
                                                </div>
                                              </div>
                                              <div style="display:table-cell;width:auto;">
                                                <span style="font-size: 12px;">{{ bundleItem.itemName }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                            <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                    <div class="item-name" style="width:100%">
                                                        <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                        <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 5%;">
                                        <div style="text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                            {{ item.quantity }}
                                        </div>
                                    </div>
                                    <div class="item-price-format" style="width: 25%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                        <div style="display: grid;">
                                            <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, item.is_member ? item.is_member : (orderDetail && orderDetail.member_id > 0 ? false : true)) }}</span>
                                            {{ getMainItemPriceAndModifiersPrice(item, item.is_member ? item.is_member : (orderDetail && orderDetail.member_id > 0 ? false : true)) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          </div>
          <div style="width: 100%;margin-top: 10px;" class="card-body pt-0" v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5">
            <div style="color: black;text-align: right;display: flex;justify-content: end;gap: 10px;">
                {{ countQuantityItems(orderDetail) }} items
            </div>
            <div class="flex-container" style="font-size: 12px; color: gray" v-if="isLogined && orderDetail.payment_amount_child">
              <div>Cashback Earned</div>
              <div class="text-container">{{ '[+' + formatPrice(orderDetail.payment_amount_child.payment_amount) + ']' }}</div>
            </div>
            <div v-if="orderDetail && orderDetail.gst < 0"> <!-- inclusive -->
                <div v-if="orderDetail && orderDetail.service_charge_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div>{{ Math.abs(orderDetail.service_charge) }}% Service Charge</div>
                    <div>{{ formatPrice(orderDetail.service_charge_amount) }}</div>
                </div>
                <div v-if="orderDetail" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div style="">Total Incl. GST</div>
                    <div style="">{{ formatPrice(orderDetail.payment_amount) }}</div>
                </div>
                <div v-if="orderDetail && orderDetail.gst_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div>{{ Math.abs(orderDetail.gst) }}% GST</div>
                    <div>{{ formatPrice(orderDetail.gst_amount) }}</div>
                </div>
            </div>
            <div v-if="orderDetail && orderDetail.gst >= 0"> <!-- exclusive -->
                <div v-if="orderDetail" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div>Subtotal</div>
                    <div>{{ formatPrice(orderDetail.total_order) }}</div>
                </div>
                <div v-if="orderDetail && orderDetail.service_charge_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div>{{ Math.abs(orderDetail.service_charge) }}% Service Charge</div>
                    <div>{{ formatPrice(orderDetail.service_charge_amount) }}</div>
                </div>
                <div v-if="orderDetail && orderDetail.gst_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div>{{ Math.abs(orderDetail.gst) }}% GST</div>
                    <div>{{ formatPrice(orderDetail.gst_amount) }}</div>
                </div>
                <div v-if="orderDetail" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                    <div style="">TOTAL</div>
                    <div style="">{{ formatPrice(orderDetail.payment_amount) }}</div>
                </div>
            </div>
            <div style="color: red;" v-if="orderDetail.closed_by_pos == 1">Payments are duplicated. Please contact cashier for help.</div>
            <div style="color: red;" v-else>
              <div v-if="orderDetail && orderDetail.topup_direct_deduct > 0">Paid By E-Wallet</div>
              <div v-else>Paid By Credit Card</div>
            </div>
          </div>
          <div v-if="orderDetail && orderDetail.is_pushed_to_megapos_database" class="card-body mt-3 pb-0">
            <div style="display: inline-flex; align-items: center; width: 100%">
              <div style="width: 100%" class="text-center">
                <div v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5">
                </div>
                <button
                  v-else
                  @click="handleProceed()"
                  class="btn bg-green text-center mb-0"
                  type="button"
                  style="
                    color: var(--color) !important;
                    background: transparent !important;
                    border-radius: 10px !important;
                    border: 1px solid var(--color) !important;
                    width: 100%;
                    padding: 10px 10px;
                    font-size: 17px;
                    font-weight: normal;
                    text-transform: none;
                  "
                >
                  New Order
                </button>
                <button
                  v-if="isOrderPayLater && this.accept_payment_on_moo == 1 && orderDetail && orderDetail.is_pushed_to_megapos_database != 2"
                  @click="handleRedirectToOrderHistoryPage()"
                  class="btn bg-green text-center mb-0"
                  type="button"
                  style="
                    color: var(--color) !important;
                    background: transparent !important;
                    border-radius: 10px !important;
                    border: 1px solid var(--color) !important;
                    width: 100%;
                    padding: 10px 10px;
                    font-size: 17px;
                    font-weight: normal;
                    text-transform: none;
                    margin-top: 10px
                  "
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
          <div
            style="
              font-size: 12px;
              margin-top: 10px;
              text-align: center;
            "
          >
            Powered by Minty™
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" :noZIndex="true" />
    <div 
      style="
        width: 200px;
        height: 100px;
        border: 2px solid green;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-45deg);
        font-weight: bold;
        color: green;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        font-size: 40px;
        border-radius: 10px;
      "
      v-if="orderDetail && orderDetail.action_required == 2 && status == 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 2 && orderDetail && orderDetail.is_pushed_to_megapos_database_real != 5"
    >
      PAID
  </div>

  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import OrderService from "../services/order.service";
import moment from "moment";
import { localStorageName } from '@/config/utils';
import $ from "jquery";
import confetti from "canvas-confetti";

export default {
  name: "Payment-success",
  components: {
    MaterialAlert,
    MaterialSnackbar,
    MaterialLoading,
  },
  data() {
    return {
      payment_order_voucher_discount_total: 0,
      payment_order_total: 0,
      payment_order_final_total: 0,
      message: "",
      loaderActive: false,
      snackbar: null,
      error: "",
      paymentMethod: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      cutleryValue: 1,
      cutleryChecked: true,
      memberId: null,
      memberPhone: null,
      memberName: null,
      showErrorName: false,
      showErrorPhone: false,
      subTotal: "$40.00",
      gstTotal: "$0.00",
      total: "$40.00",
      totalToPay: 40,
      itemSelect: [],
      payment_order_id: this.$route.params.order_id ?? null,
      payment_order_gst_total: 0,
      payment_order_service_charge_total: 0,
      status: 0,
      created_at: null,
      latestUpdateTime: moment().format("DD/MM//YYYY hh:mm A"),
      orderDetail: null,
      MERCHANT_BRAND_NAME: localStorage.getItem(localStorageName('MERCHANT_BRAND_NAME'), ''),
      cartName: 'ORDER_INFO',
      merchantCode: "",
      branchCode: "",
      pathType: "",
      tableCode: "",
      interval: null,
      typeOrder: null,
      message_error_from_megapos: "",
      tableInfo: null,
      isOrderPayLater: false,
      date_time_request_action_required_to_megapos: null,
      intervalId: null,
      timeoutId: null,
      accept_payment_on_moo: null,
      isLogined: false,
      isRequestTimeOut: false,
      confettiActive: false
    };
  },
  async mounted() {
    this.cartName = await this.$store.dispatch('cartName');
    // let timerId = setInterval(async () => {
    //   await this.getOrderByOrderId();
    // }, 10000);

    // onUnmounted(() => {
    //   console.log("a");
    //   clearInterval(timerId);
    // });

    this.interval = setInterval(async () => {
      if(!this.$route.query.minty_transaction_id) {
        await this.getOrderByOrderId();

        if (!this.confettiActive && this.orderDetail && this.orderDetail.is_pushed_to_megapos_database) {
            this.confettiActive = true
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
      }
    }, 3000);

    //load after payment done
    this.showLoader();
    var session_id = this.$route.query.session_id ?? "";
    // var order_id = this.$route.params.order_id ?? "";
    // var type_order = this.$route.params.type_order ?? "";
    var transaction_id = this.$route.query.transaction_id;
    var minty_transaction_id = this.$route.query.minty_transaction_id ?? 0;
    var price = this.$route.query.price ?? 0;
    // var type = this.$route.query.type ?? "";
    if(minty_transaction_id) {
      if(session_id) {
        this.loaderActive = true;
        var dataForm1 = {
          member_id: this.memberId,
          session_id: session_id,
          transaction_id: transaction_id,
          price: price,
        };
        await OrderService.saveTransactionByOrderAfterPayStripe(dataForm1).then(
          async (response) => {
            if (response.data.result.isSuccess) {
              var data = response.data.data;
              var dataForm = {
                  minty_transaction_id: data.transaction_id,
                  branch_code: this.$store.state.branchCode,
                  table_code:  this.$route.params.table_code,
                  action_required: 2
              }

              await OrderService.requestActionRequired(dataForm).then(
                  (response) => {
                      if (response.data.result.isSuccess) {
                        if(response.data.data.is_pos_offline) {
                          console.log("megapos offline");
                        } else {
                          if(response.data.data.is_expired == 1) {
                            console.log("exprired order");
                          } else {
                            // console.log("response.data.data", response.data.data);
                            this.date_time_request_action_required_to_megapos = response.data.data.date_time_request_action_required_to_megapos;
                            if(response.data.data.is_pushed_to_megapos_database != 2) {
                              this.startPolling(response.data.data.id);
                            } else {
                              const merchantCode = this.$store.state.merchantCode;
                              const branchCode = this.$store.state.branchCode;
                              var url = location.protocol + '//' + location.host+"/"+merchantCode;
                              url += "/QR/" + branchCode + "/" + this.tableCode + "/payment-success/" + response.data.data.transaction_id;
                              window.location.href = url;
                            }
                          }
                        }
                      } else {
                          this.snackbar = "danger";
                          this.message = response.data.result.message;
                          this.setTimeOutNotification();
                      }
                      // this.hideLoader();
                  },
                  (error) => {
                      this.snackbar = "danger";
                      this.message = error;
                      this.hideLoader();
                  }
              );
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.loaderActive = false;
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.loaderActive = false;
          }
        );
        this.hideLoader();
      } else {
        this.loaderActive = true;
        var dataForm2 = {
          member_id: this.memberId,
          transaction_id: minty_transaction_id,
          price: price,
        };
        await OrderService.saveTransactionByOrderAfterPayMintyWallet(dataForm2).then(
          async (response) => {
            if (response.data.result.isSuccess) {
              var data = response.data.data;
              var dataForm = {
                  minty_transaction_id: data.transaction_id,
                  branch_code: this.$store.state.branchCode,
                  table_code:  this.$route.params.table_code,
                  action_required: 2
              }

              await OrderService.requestActionRequired(dataForm).then(
                  (response) => {
                      if (response.data.result.isSuccess) {
                        if(response.data.data.is_pos_offline) {
                          console.log("megapos offline");
                        } else {
                          if(response.data.data.is_expired == 1) {
                            console.log("exprired order");
                          } else {
                            if(response.data.data.wallet_not_enough != 1) {
                              // console.log("response.data.data", response.data.data);
                              this.date_time_request_action_required_to_megapos = response.data.data.date_time_request_action_required_to_megapos;
                              if(response.data.data.is_pushed_to_megapos_database != 2) {
                                this.startPolling(response.data.data.id);
                              } else {
                                const merchantCode = this.$store.state.merchantCode;
                                const branchCode = this.$store.state.branchCode;
                                var url = location.protocol + '//' + location.host+"/"+merchantCode;
                                url += "/QR/" + branchCode + "/" + this.tableCode + "/payment-success/" + response.data.data.transaction_id;
                                window.location.href = url;
                              }
                            } else {
                              this.orderDetail = response.data.data;
                              console.log("wallet_not_enough");
                            }
                          }
                        }
                      } else {
                          this.snackbar = "danger";
                          this.message = response.data.result.message;
                          this.setTimeOutNotification();
                      }
                      // this.hideLoader();
                  },
                  (error) => {
                      this.snackbar = "danger";
                      this.message = error;
                      this.hideLoader();
                  }
              );
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.loaderActive = false;
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.loaderActive = false;
          }
        );
        this.hideLoader();
      }
    } else {
      if (session_id && transaction_id) {
        // call back after pay success - stripe
        this.loaderActive = true;
        var dataForm = {
          member_id: this.memberId,
          session_id: session_id,
          transaction_id: transaction_id,
          price: price,
        };
        await OrderService.saveTransactionByOrder(dataForm).then(
          async (response) => {
            if (response.data.result.isSuccess) {
              var data = response.data.data;
              localStorage.removeItem(this.cartName);
              localStorage.removeItem(localStorageName('SELECTED_REWARD'));
              if (!data.is_member) {
                var orderIdNonMember = JSON.parse(localStorage.getItem(localStorageName('ORDER_ID_NON_MEMBER'), null)) ?? [];
                orderIdNonMember.push(data.transaction_id);
                localStorage.setItem(localStorageName('ORDER_ID_NON_MEMBER'), JSON.stringify(orderIdNonMember));
              }
              await this.getOrderByOrderId();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.loaderActive = false;
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.loaderActive = false;
          }
        );
        this.hideLoader();
      } else {
        await this.getOrderByOrderId();
      }
    }
  },
  unmounted() {
    clearInterval(this.interval);
  },
  created() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;
    this.typeOrder = this.$route.params.type_order ? 2 : 1;
    var memberInfoData = JSON.parse(localStorage.getItem(localStorageName('memberInfo'), ''));

    if (memberInfoData) {
      this.isLogined = true;
      this.memberId = memberInfoData.id ?? 0;
      this.memberName = memberInfoData.name;
      this.memberPhone =
        "+" + memberInfoData.phone_code + memberInfoData.phone_number;
    }

    this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), null));
    if (this.tableInfo) {
        this.accept_payment_on_moo = this.tableInfo.accept_payment_on_moo;
        if (this.tableInfo.stripe_connect) {
            this.isOrderPayLater = false;
        } else {
            this.isOrderPayLater = true;
        }
    } else {
        this.isOrderPayLater = false;
    }
  },
  computed: {
    styleObject() {
      return {
          "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },
    selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    goBack() {
      this.$router.push({ name: "Order", params: {
        merchant_code: this.merchantCode,
        type: this.pathType,
        branch_code: this.branchCode,
        table_code: this.tableCode
      } });
    },
    handleProceed() {
      // localStorage.removeItem("PAYMENT_ORDER_INFORMATION_SUCCESS");
      // localStorage.removeItem("PAYMENT_ORDER_INFO");
      // localStorage.removeItem("PAYMENT_ORDER_VOUCHER_DISCOUNT_TOTAL");
      // localStorage.removeItem("PAYMENT_ORDER_TOTAL");
      // localStorage.removeItem("PAYMENT_ORDER_FINAL_TOTAL");
      // localStorage.removeItem("PAYMENT_ORDER_ID");
      // localStorage.removeItem("ORDER_INFORMATION_SUCCESS");
      // localStorage.removeItem("PAYMENT_ORDER_GST_TOTAL");
      // localStorage.removeItem("PAYMENT_ORDER_SERVICE_CHARGE_TOTAL");
      this.gotoPath('Order');
    },
    async getOrderByOrderId() {
      this.latestUpdateTime = moment().format("DD/MM/YYYY hh:mm A");
      const order_id = this.$route.params.order_id ?? null;

      if (!order_id) {
        this.gotoPath('Order');
      }

      // this.loaderActive = true;
      var dataForm = {
        member_id: this.memberId,
        transaction_id: order_id,
        branch_code: this.branchCode,
        type_order: this.typeOrder
      };

      await OrderService.getOrderByOrderId(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.orderDetail = response.data.data;
            this.itemSelect = response.data.data.items_ordering_data;
            this.payment_order_voucher_discount_total =
              response.data.data.discount_value;
            // if(response.data.data.payment_method == "MINTY WALLET") {
            //     this.payment_order_final_total = response.data.data.amount_direct_deduct;
            // } else {
            //     this.payment_order_final_total = response.data.data.payment_amount;
            // }
            // const numericValue = parseFloat(this.branch.gst_rate);
            // if(numericValue < 0) { // exclusive
            //     this.payment_order_gst_total = response.data.data.gst_amount;
            //     this.payment_order_total = response.data.data.total_order;
            // } else { // inclusive
            //     this.payment_order_total = response.data.data.payment_amount;
            //     this.payment_order_gst_total = 0
            // }
            this.payment_order_total = response.data.data.total_order;
            this.payment_order_gst_total = response.data.data.gst_amount;
            this.payment_order_service_charge_total =
              response.data.data.service_charge_amount;

            this.payment_order_final_total = response.data.data.total_amount;

            if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 1
            ) {
              this.status = 1;
              this.hideLoader();
            } else if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 2
            ) {
              this.status = 2;
              this.hideLoader();
            } else {
              this.status = 0;
              // this.showLoader();
              this.hideLoader();
            }
            this.created_at = response.data.data.created_at;
            this.branch.waiting_time_text = this.orderDetail.watting_time;
            this.message_error_from_megapos = response.data.data.message_error_from_megapos ? response.data.data.message_error_from_megapos : "";
          } else {
            this.hideLoader();
            // var url =
            //   location.protocol +
            //   "//" +
            //   location.host +
            //   "/" +
            //   merchantCode +
            //   "/" +
            //   branchCode +
            //   "/order";
            // window.location.href = url;
          }
          // this.loaderActive = false;
        },
        (error) => {
          this.message = error;
          this.hideLoader();
          // var url =
          //   location.protocol +
          //   "//" +
          //   location.host +
          //   "/" +
          //   merchantCode +
          //   "/" +
          //   branchCode +
          //   "/order";
          // window.location.href = url;
          // this.loaderActive = false;
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    gotoPath(name) {
      this.$router.push({ name: name, params: {
          merchant_code: this.merchantCode,
          type: this.pathType,
          branch_code: this.branchCode,
          table_code: this.tableCode
      } });
    },
    getMainItemPriceAndModifiersPrice(item, isNonMember) {
        var priceMainAndModifer = 0;
        if (this.tableCode) {
            priceMainAndModifer = item.is_member && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
        } else {
            priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
        }

        var totalPriceModifer = 0;
        if (item.modifier_group) {
            item.modifier_group.forEach((group) => {
                group.items.forEach((itemModifier) => {
                    if (isNonMember) {
                      totalPriceModifer += itemModifier.price_default * itemModifier.quantity;
                    } else {
                      totalPriceModifer += itemModifier.member_price * itemModifier.quantity;
                    }
                });
            });
        }

        return this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity));
    },
    getMainItem(item, isNonMember) {
      var priceMainAndModifer = 0;
      if (this.tableCode) {
          isNonMember = !item.is_member;
      }
      priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.price_default_no_modifier_option * item.quantity : 0;

      var totalPriceModifer = 0;
      if (item.modifier_group) {
          item.modifier_group.forEach((group) => {
              group.items.forEach((itemModifier) => {
                if (isNonMember) {
                  totalPriceModifer += itemModifier.price_default * itemModifier.quantity;
                } else {
                  totalPriceModifer += itemModifier.member_price * itemModifier.quantity;
                }
              });
          });
      }
      
      return priceMainAndModifer > 0 ? this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity)) : "";
    },
    formatPrice(price) {
        return '$ ' + parseFloat(price).toFixed(2);
    },
    handleRetry() {
      const order_id = this.$route.params.order_id ?? null;

      if (!order_id) {
        this.gotoPath('Order');
      }

      var dataForm = {
        request_id: order_id,
        branch_code: this.branchCode
      };

      OrderService.retryOrderPayLater(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // this.snackbar = "success";
            // this.message = "Retry Successfully!";
            // this.setTimeOutNotification();
            this.getOrderByOrderId();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    handleRedirectToOrderHistoryPage() {
      this.gotoPath('OrderHistory');
    },
    startPolling(id) {
        this.getOrderByOrderIdForActionRequired(id);

        this.intervalId = setInterval(() => {
            this.getOrderByOrderIdForActionRequired(id);
        }, 3000);

        this.timeoutId = setTimeout(() => {
            clearInterval(this.intervalId);
            clearTimeout(this.timeoutId);
            this.hideLoader();
            $(".btn-close-modal").click();
            this.isRequestTimeOut = true;
            // location.reload();
            
        }, 180000);
    },
    getOrderByOrderIdForActionRequired(id) {
      this.showLoader();
        var dataForm = {
            minty_transaction_id: id,
            branch_code: this.branchCode,
            table_code: this.tableCode
        };

        OrderService.getOrderByOrderIdForActionRequired(dataForm).then(
        (response) => {
            if (response.data.result.isSuccess) {
                if(response.data.data.date_time_request_action_required_to_megapos !== this.date_time_request_action_required_to_megapos) {
                    
                    if(response.data.data.closed_by_pos == 1) {
                      // handle refund
                      // this.handleRefundOrder(response.data.data.id);
                    } else {
                      // handle save closed order
                      // this.closeOrderAfterCheckedWasTenderOnPos(response.data.data.id);
                  }
                  location.reload();
                    clearInterval(this.intervalId);
                    clearTimeout(this.timeoutId);
                    // if(response.data.data.message_after_request_action_required != "" && response.data.data.message_after_request_action_required == "Online payment is not available. Please make payment at the counter.") {
                    //     this.messsageErrorRequestActionTotalAmount = response.data.data.message_after_request_action_required;
                    //     $(".btn-total-amount-order-modal").click();
                    // } else if(response.data.data.message_after_request_action_required != "" && response.data.data.message_after_request_action_required == "This order has been paid.") {
                    //     this.messsageErrorRequestActionPaidOrder = response.data.data.message_after_request_action_required;
                    //     $(".btn-paid-order-modal").click();
                    // } else {
                    //     // handle stripe here
                    //     this.processPaymentStripe(response.data.data.transaction_id, response.data.data.payment_amount);
                    // }
                    // this.hideLoader();
                }
            }
        },
        (error) => {
            console.log("error", error);
        }
        );
    },
    closeOrderAfterCheckedWasTenderOnPos(id) {
      this.showLoader();
        var dataForm = {
            minty_transaction_id: id,
            branch_code: this.branchCode,
            table_code: this.tableCode
        };

        OrderService.closeOrderAfterCheckedWasTenderOnPos(dataForm).then(
        (response) => {
            if (response.data.result.isSuccess) {
                location.reload();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
        },
        (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
        }
        );
    },
    // handleRefundOrder(id) {
    //   this.showLoader();
    //     var dataForm = {
    //         minty_transaction_id: id,
    //         branch_code: this.branchCode,
    //         table_code: this.tableCode
    //     };

    //     OrderService.handleRefundOrder(dataForm).then(
    //     (response) => {
    //         if (response.data.result.isSuccess) {
    //             this.snackbar = "success";
    //             this.message = "Refund successfully!";
    //             this.setTimeOutNotification();
    //             location.reload();
    //         } else {
    //           this.snackbar = "danger";
    //           this.message = response.data.result.message;
    //           this.setTimeOutNotification();
    //         }
    //         this.hideLoader();
    //     },
    //     (error) => {
    //         this.snackbar = "danger";
    //         this.message = error;
    //         this.setTimeOutNotification();
    //         this.hideLoader();
    //     }
    //     );
    // },
    countQuantityItems(item) {
      var totalQuantityItems = 0;

      item && item.items_ordering_data.forEach((mainItem) => {
          totalQuantityItems += mainItem.quantity;
          // mainItem.modifier_group.forEach((group) => {
          //     group.items.forEach((itemModifier) => {
          //         totalQuantityItems += itemModifier.quantity;
          //     });
          // });
      });

      return totalQuantityItems;
    },
    dateFormat(date) {
        if (date == null) {
            return null;
        } else {
            return moment(date).format("YYYY-MM-DD HH:mm");
        }
    },
    handleRetryWalletNotEnough() {
      const minty_transaction_id = this.$route.query.minty_transaction_id ?? 0;

      if (!minty_transaction_id) {
        this.gotoPath('Order');
      }

      var dataForm = {
        minty_transaction_id: minty_transaction_id,
        branch_code: this.branchCode
      };

      OrderService.retryOrderWalletNotEnough(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // this.snackbar = "success";
            // this.message = "Retry Successfully!";
            // this.setTimeOutNotification();
            this.startPolling(minty_transaction_id);
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
  },
};
</script>

<style scoped>
.status-active {
  margin-top: 5px !important;
  color: var(--color);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  margin: 0 5px;
  background-color: #000;
  border-radius: 50%;
  animation: dot-blink 1.5s infinite;
  margin-top: 8px;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.circle-x {
  width: 13.1px;
  height: 13.1px;
  background: red;
  border-radius: 50%;
  position: relative;
}

.circle-x::before,
.circle-x::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 10px;
  background: white;
  transform-origin: center;
}

.circle-x::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.circle-x::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

#page-success {
  font-family: "Roboto Slab", sans-serif !important;
}
</style>
